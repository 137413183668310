export const cleoGreen = '#4d9fd9';
export const cleoLightGreen = '#70b2e0';
export const cleoDarkGreen = '#356f97';
export const cleoOrange = '#FF6859';
export const cleoOrangeLight = '#FF8A80';
export const cleoOrangeDark = '#C62828';
export const cleoYellow = '#FFCF44';
export const cleoPurple = '#B15DFF';
export const cleoBlue = '#72DEFF';

export default {
  themeName: 'Light',
  typography: {
    fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    type: 'light',
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#57A9DE',
      light: 'rgb(166, 212, 250)',
      dark: 'rgb(100, 141, 174)',
      darken: '#172B4D',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#FFAA01',
      light: 'rgb(246, 165, 192)',
      dark: 'rgb(170, 100, 123)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      light: '#e57373',
      main: '#FF3635',
      gradient: '#FEE8E7',
      dark: '#d32f2f',
    },
    healthy: {
      main: '#02DE27',
      gradient: '#c8e6c9',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      1000: '#2E394D',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: '#2A555B',
      secondary: 'rgba(0, 0, 0, 0.7)',
      disabled: 'rgba(0, 0, 0, 0.5)',
      hint: 'rgba(0, 0, 0, 0.5)',
      icon: 'rgba(0, 0, 0, 0.5)',
      level2: '#EBECF0',
      header: '#2A555B',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    listDevider: {
      default: '#E6E6E6',
      light: 'rgba(73,85,105,0.5)',
    },
    background: {
      default: '#ECEFF1',
      paper: '#FFFFFF',
      drawer: '#FFF',
      active: '#FFFFFF',
      light: '#EEF6F7',
      level1: '#212121',
      level2: '#333',
      panel: '#FFF',
      header: '#FFFFFF',
    },
    action: {
      active: '#2A555B',
      hover: 'rgba(0, 0, 0, 0.1)',
      hoverOpacity: 0.1,
      selected: 'rgba(0, 0, 0, 0.2)',
      disabled: 'rgba(0, 0, 0, 0.3)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    typography: {
      fontFamily:
        "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      htmlFontSize: 16,
    },
    shadow: {
      black: 'rgba(0,0,0,0.5)',
    },
    // custom colors
    gridLine: 'rgba(216,216,216,0.2)',
    customWhite: '#E0E4ED',
    customWhite2: '#EBECF0',
    customWhite3: '#D8D8D8',
    nile: {
      bermudaBlue: '#1E57F7',
      headerBg: '#b3e5fc',
      papaya: '#FF0092',
    },
  },
};
