/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-wrap-multilines */

import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';
// import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {
  ValidatorForm,
  TextValidator,
} from 'react-material-ui-form-validator';

import SearchPlaces from 'sphinx/dist/maps/SearchPlaces';
import callingCodes from 'sphinx/dist/common/data/CallingCodes';

import getLogger from '../../common/Logger';
import karnakService from '../../apis/KarnakService';
import useGlobal from '../../store';
import {
  isValidPhoneNumber,
  getFormatedPhoneNumber,
  getFormatNational,
} from '../../common/ValidationUtils';
import { formatUTC } from '../../common/Formats';
import getI18nService from '../../i18n/i18n';
import useStyles from './Signup.jss';
// import Privacy from '../terms/Privacy';

const i18n = getI18nService();

const karnakConfig = window['KARNAK_CONFIG'] || {};
// const siteKey = karnakConfig.captchaSiteKey;
const {
  captchaThreshold,
  captchaSiteKey,
  prodHost,
  useCaptcha,
} = karnakConfig;

export default function SelfSignup() {
  const history = useHistory();
  const classes = useStyles();
  const [globalState, globalActions] = useGlobal();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailRegistered, setIsEmailRegistered] = useState(false);
  const [
    isEmailPersonalAccount,
    setIsEmailPersonalAccount,
  ] = useState(false);
  // const [showPrivacy, setShowPrivacy] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [tosAcceptedDate, setTosAcceptedDate] = useState(null);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const [tenant, setTenant] = React.useState({
    region: 'north_america',
    countryCode: callingCodes.find(cd => cd.code === 'US'),
    country: callingCodes.find(cd => cd.code === 'US'),
  });
  const formRef = useRef(null);
  // const useCaptcha = false;
  // window.location.host.indexOf('nile-global.com') > -1;

  const validateForm = () => {
    if (formRef.current) {
      formRef.current.isFormValid().then(res => {
        setIsFormValid(res);
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const host = window.location.host;
    if (host === 'www.nile-global.cloud') {
      console.log('restrict private email providers');
    }
  }, []);

  const handleChange = name => (event, value) => {
    if (name === 'privacy') {
      setPrivacyChecked(event.target.checked);
      if (event.target.checked) {
        setTosAcceptedDate(dayjs(new Date()).format(formatUTC));
      } else {
        setTosAcceptedDate(null);
      }
    } else if (event && event.target && value) {
      // seperate check for region as both value and event.target.value are available in this scenario
      if (name === 'region') {
        setTenant({ ...tenant, [name]: event.target.value });
      } else {
        setTenant({ ...tenant, [name]: value });
      }
    } else if (event && event.target) {
      setTenant({ ...tenant, [name]: event.target.value });
    } else {
      setTenant({ ...tenant, [name]: event });
    }
    globalActions.setIsFormDirty(true);
  };

  const handleClearAll = () => {
    setTenant({
      region: 'north_america',
      countryCode: callingCodes.find(cd => cd.code === 'US'),
      country: callingCodes.find(cd => cd.code === 'US'),
      email: '',
      fullName: '',
      company: '',
      street1: '',
      state: '',
      city: '',
      formattedAddress: '',
      latitude: null,
      longitude: null,
      zip: null,
      mobilePhone: '',
    });
    globalActions.setIsFormDirty(false);
  };

  const getSlackData = () => {
    const payload = {
      address: tenant.formattedAddress,
      email: tenant.email,
      fullName: tenant.fullName,
      name: tenant.company,
      phone: tenant.mobilePhone,
      submittedOn: new Date().toLocaleString(),
      tosAcceptedDate,
    };
    return {
      type: 'success',
      payload,
    };
  };

  const slackFailedSignup = async (failedAddress, failedTenant) => {
    const data = {
      type: 'fail',
      payload: {
        address: failedAddress,
        tenant: failedTenant,
      },
    };
    const slackResp = await globalActions.executeApi(
      Object.assign({}, karnakService.PostSlack, {
        data,
      })
    );
    if (slackResp) {
      console.log(
        `Slack response was received for failed signup ${JSON.stringify(
          slackResp
        )}`
      );
    }
  };

  const slackFailedCaptcha = async score => {
    const {
      fullName,
      email,
      name,
      street1,
      city,
      zip,
      state,
      country,
    } = tenant;
    const payload = `${fullName} - ${email} - ${name} - ${street1} - ${city} - ${zip} - ${state} - ${country}`;
    const data = {
      type: 'fail-captcha',
      score,
      payload,
    };
    const slackResp = await globalActions.executeApi(
      Object.assign({}, karnakService.PostSlack, {
        data,
      })
    );
    if (slackResp) {
      console.log(
        `Slack response was received for failed signup ${JSON.stringify(
          slackResp
        )}`
      );
    }
  };

  const verifyCaptcha = async (message, token) => {
    const data = {
      message,
      token,
    };
    const captchaResp = await globalActions.executeApi(
      Object.assign({}, karnakService.verifyCaptcha, {
        data,
      })
    );
    if (captchaResp) {
      console.log(
        `Captcha response was received for signup ${JSON.stringify(
          captchaResp
        )}`
      );
      return captchaResp;
    }
    return null;
  };

  async function postData() {
    const {
      email,
      fullName,
      company,
      name,
      street1,
      city,
      zip,
      region,
      state = '',
      country,
      latitude,
      longitude,
    } = tenant;
    const tenantData = {
      email,
      fullName,
      street1,
      city,
      state,
      zip,
      country: country.isoCode,
      region,
      mobilePhone: getFormatedPhoneNumber(
        `+${tenant.countryCode.value} ${tenant.mobilePhone}`
      ),
      latitude,
      longitude,
    };
    tenantData.name = company;
    if (tenantData.state.length === 0) {
      tenantData.state = '--';
    }
    if (privacyChecked) {
      tenantData.tosAcceptedDate = tosAcceptedDate;
    }
    console.log(JSON.stringify(tenantData));
    setInProgress(true);
    const signupResponse = await globalActions.executeApi(
      Object.assign({}, karnakService.signup, {
        data: tenantData,
      })
    );
    if (!signupResponse || signupResponse.error) {
      getLogger().error('Failed to signup a tenant');
      setInProgress(false);
      window.signupSuccess = true;
      history.push('/home?signupSuccess=false');
      return;
    }

    if (signupResponse) {
      const slackResp = await globalActions.executeApi(
        Object.assign({}, karnakService.PostSlack, {
          data: getSlackData(),
        })
      );
      if (slackResp) {
        console.log(
          `Slack response was received ${JSON.stringify(slackResp)}`
        );
      }
      handleClearAll();
      window.signupSuccess = true;
      setInProgress(false);
      history.push('/home?signupSuccess=true');
    } else {
      const newTenant = signupResponse.tenant;
      const addressResponse = signupResponse.address;
      slackFailedSignup(addressResponse, newTenant);
    }
  }

  async function handleSubmit(event) {
    globalActions.setIsFormDirty(false);
    if (window.grecaptcha && useCaptcha === 'true') {
      window.grecaptcha
        .execute(captchaSiteKey, { action: 'new_signup' })
        .then(async token => {
          console.log(`Captcha client token generated ${token}`);
          if (token) {
            const resp = await verifyCaptcha('new_signup', token);
            console.log(
              `Captch verify results ${JSON.stringify(resp)}`
            );
            const treshold = parseFloat(captchaThreshold);
            if (resp.score > treshold) {
              // will find a better solution by the time state is mutated it display annoying confirmation dialog.
              setTimeout(postData, 250);
            } else {
              slackFailedCaptcha(resp.score);
            }
          } else {
            console.error(
              'Captcha token was not genarated, shall we allow that!'
            );
          }
        });
    } else {
      // will find a better solution by the time state is mutated it display annoying confirmation dialog.
      console.warn(
        'Captcha was not available or is disabled submitting without verifying.'
      );
      setTimeout(postData, 250);
    }
  }

  function handlePlaceChange(
    addr = {
      name: '',
      street1: '',
      state: '',
      city: '',
      formattedAddress: '',
      latitude: null,
      longitude: null,
      zip: null,
    }
  ) {
    const isValidAddr =
      addr.zip || addr.city || addr.latitude || addr.longitude;
    setIsValidAddress(isValidAddr);
    setTenant(ten => ({
      ...ten,
      ...addr,
      country: callingCodes.find(cd => cd.isoCode === addr.country),
    }));
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('validatePhoneNumber', value => {
      if (!value || value.length === 0) {
        return true;
      }
      const phoneNumber = `+${tenant.countryCode.value} ${value}`;
      return isValidPhoneNumber(phoneNumber);
    });

    validateForm();

    return () => {
      ValidatorForm.removeValidationRule('validatePhoneNumber');
    };
  }, [tenant]);

  const checkIfEmailIsRegistered = async email => {
    const result = await globalActions.executeApi(
      Object.assign({}, karnakService.CheckIfEmailIsRegistered, {
        data: { email },
      })
    );
    if (result === undefined) {
      setIsEmailRegistered(false);
    } else {
      const { status } = result;
      if (status && status === 200) {
        setIsEmailRegistered(true);
      }
    }
  };
  const checkIfEmailIsPersonalAccount = async email => {
    // eslint-disable-next-line prefer-destructuring
    const host = window.location.host;
    if (host !== prodHost) {
      return;
    }
    const result = await globalActions.executeApi(
      Object.assign({}, karnakService.verifyemail, {
        params: { email },
      })
    );
    if (result) {
      const { valid, reason } = result;
      if (valid) {
        setIsEmailPersonalAccount(false);
      } else {
        setIsEmailPersonalAccount(true);
      }
    }
  };

  const handleBlur = name => (event, value) => {
    if (name === 'email') {
      const email = event.target.value;
      if (email && email.length > 3) {
        checkIfEmailIsRegistered(email);
        checkIfEmailIsPersonalAccount(email);
      }
    }
    if (name === 'mobilePhone') {
      const mobilePhone = event.target.value;
      const formatted = getFormatNational(
        `+${tenant.countryCode.value} ${mobilePhone}`
      );
      setTenant({ ...tenant, [name]: formatted });
    }

    return true;
  };

  // const handleShowPrivacy = event => {
  //   event.preventDefault();
  //   setShowPrivacy(true);
  // };

  // const handlePrivacyClose = open => {
  //   setShowPrivacy(false);
  // };

  const getEmailHelpText = () => {
    let txt = '';
    if (isEmailRegistered && isEmailPersonalAccount) {
      txt = i18n.t('Signup.emailRegisteredAndNotCorporate');
    } else if (isEmailRegistered) {
      txt = i18n.t('Signup.emailAlreadyRegistered');
    } else if (isEmailPersonalAccount) {
      txt = i18n.t('Signup.emailCorporateHelpText');
    } else {
      txt = i18n.t('Signup.emailHelper');
    }
    return txt;
  };

  const getQuote = () => (
    <Box>
      <Typography variant="h2" align="center">
        &quot;70%
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
        of network issues are
        <br />
        due to
        <br />
        misconfiguration&quot;
      </Typography>
      <Typography variant="h5" align="center">
        -&nbsp;Dell&apos;&nbsp;Oro
      </Typography>
    </Box>
  );

  return (
    <Paper className={classes.root}>
      <Box width="100%" maxHeight={700} display="flex">
        <ValidatorForm
          className={classes.form}
          autoComplete="off"
          ref={formRef}
          onSubmit={() => {}}
        >
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Box
              item
              xs={12}
              p={1}
              justifyContent="center"
              display="flex"
              alignItems="baseline"
            >
              <Typography
                variant="h5"
                align="center"
                component="span"
              >
                {i18n.t('Signup.signupTitle')}
              </Typography>
            </Box>
            <Box flexGrow="1">
              <Grid
                className={classes.gridContainer}
                container
                spacing={1}
                height="100%"
              >
                <Grid item xs={12} sm={12}>
                  <TextValidator
                    id="email"
                    className={classes.textField}
                    label={i18n.t('Vocabulary.email')}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={tenant.email}
                    size="small"
                    fullWidth
                    validators={['required', 'isEmail']}
                    variant="outlined"
                    errorMessages={[
                      'this field is required',
                      'Enter valid email',
                      'already exists',
                    ]}
                    helperText={getEmailHelpText()}
                    error={
                      isEmailRegistered || isEmailPersonalAccount
                    }
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    inputProps={{
                      autoComplete: 'false',
                      tabIndex: '1',
                    }}
                  />
                  <TextValidator
                    id="fullName"
                    label={i18n.t('Vocabulary.fullName')}
                    onChange={handleChange('fullName')}
                    className={classes.textField}
                    value={tenant.fullName}
                    fullWidth
                    size="small"
                    validators={['required']}
                    variant="outlined"
                    errorMessages={['this field is required']}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    inputProps={{
                      autoComplete: 'false',
                      tabIndex: '2',
                    }}
                  />
                  <TextValidator
                    id="companyName"
                    className={classes.textField}
                    label={i18n.t('Vocabulary.company')}
                    onChange={handleChange('company')}
                    value={tenant.company}
                    variant="outlined"
                    size="small"
                    fullWidth
                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    inputProps={{
                      autoComplete: 'false',
                      tabIndex: '3',
                    }}
                  />
                  <Box width="100%" className={classes.searchPlaces}>
                    <SearchPlaces
                      className={classes.searchInput}
                      multiline
                      required
                      variant="outlined"
                      address={tenant}
                      inputProps={{
                        autoComplete: 'false',
                        tabIndex: '4',
                      }}
                      onPlaceChange={handlePlaceChange}
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                  >
                    <Box width="50%" pr={1}>
                      <Autocomplete
                        className={classes.textFieldOne}
                        size="small"
                        data-id="react-select-countryCode"
                        options={callingCodes}
                        getOptionLabel={cd =>
                          cd ? `(${cd.value}) - ${cd.country}` : ''
                        }
                        onChange={handleChange('countryCode')}
                        value={tenant.countryCode}
                        inputProps={{
                          autoComplete: 'false',
                          tabIndex: '5',
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Country Code"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                    <Box width="50%" pl={1}>
                      <TextValidator
                        id="mobilePhone"
                        className={classes.textFieldOne}
                        label={i18n.t('Vocabulary.phone')}
                        onChange={handleChange('mobilePhone')}
                        onBlur={handleBlur('mobilePhone')}
                        value={tenant.mobilePhone}
                        variant="outlined"
                        fullWidth
                        size="small"
                        validators={['validatePhoneNumber']}
                        errorMessages={['Enter valid phone number']}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          autoComplete: 'false',
                          tabIndex: '6',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box height={80} pt={2}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyChecked}
                      color="primary"
                      onChange={handleChange('privacy')}
                      name="privacy"
                      inputProps={{
                        tabIndex: '7',
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.privacyLabel}>
                      {i18n.t('Signup.tcText')}
                      <Link
                        className={classes.privacyLink}
                        href="/content/NileGlobalPrivacyPolicy.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18n.t('Signup.privacyPolicies')}
                      </Link>
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
            <Box
              height={85}
              display="flex"
              flexDirection="row"
              alignItems="space-between"
              className={classes.buttons}
            >
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
              >
                <Box>
                  <Button
                    disabled={
                      !isFormValid ||
                      isEmailRegistered ||
                      !privacyChecked ||
                      isEmailPersonalAccount ||
                      !isValidAddress
                    }
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="8"
                    alignself="right"
                    className={classes.button}
                    onClick={handleSubmit}
                  >
                    {i18n.t('Signup.createAccount')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          {inProgress && (
            <Typography align="center" variant="body2">
              Registration in progress...
            </Typography>
          )}
          {/* <Box display="flex" width="50%" pl={4}>
            <TextField
              id="region"
              select
              label={i18n.t('Address.region')}
              fullWidth
              className={classes.textField}
              value={tenant.region}
              helperText={i18n.t('Signup.regionHelper')}
              onChange={handleChange('region')}
              inputProps={{ autoComplete: 'false', tabIndex: '8' }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {regionCountry.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Box> */}
        </ValidatorForm>
        {/* <ScrollDialog
          onClose={handlePrivacyClose}
          title="Privacy policies"
          open={showPrivacy}
        >
          <Privacy />
        </ScrollDialog> */}
      </Box>
    </Paper>
  );
}
